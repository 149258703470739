import React from "react";
import { Modal, Button, Container as BootstrapContainer } from "react-bootstrap";
import ContactForm from "../ContactForm/Contactpopup";
import styled from "styled-components";

const ModalComponent = ({ show, handleClose }) => {
  const handleFormSubmit = (e) => {
    // Handle form submission here
    handleClose(); // Close the modal after form submission
  };

  return (
    <Container>
      <BootstrapContainer className="me-5">
        <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
          <CloseButton variant="close" onClick={handleClose} aria-label="Close" />
          <Content>
            <ContactForm
              sectionTitleUp="WAITING FOR PREGNANCY ?"
              sectionTitle="Contact Us"
              onSubmit={handleFormSubmit} // Pass the handleFormSubmit function
            />
          </Content>
        </Modal>
      </BootstrapContainer>
    </Container>
  );
};

export default ModalComponent;

const Container = styled.div`
  .custom-modal .modal-dialog {
    max-width: 100%;
    margin: auto;
    @media (min-width: 576px) {
      max-width: 540px; /* Mobile devices */
    }
    @media (min-width: 768px) {
      max-width: 720px; /* Tablets */
    }
    @media (min-width: 992px) {
      max-width: 960px; /* Laptops */
    }
    @media (min-width: 1200px) {
      max-width: 1140px; /* Large screens */
    }
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Content = styled.div`
  padding: 20px;
  text-align: center;
  color: white;
`;
