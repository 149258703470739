import React from "react";
import RecentPostWidget from "../Widget/RecentPostWidget";

const recentPostData = [
  {
    title: `Best Infertility Doctors for Male in Jabalpur`,
    author: "Dr. Archana Srivastava",
    date: "14 December, 2024",
    href: "/Best-Male-Infertility-Treatment-in-Jabalpur",
  },
  {
    title: `Best Infertility Treatment Doctor in Jabalpur`,
    author: "Dr. Archana Srivastava",
    date: "5 January, 2025",
    href: "/Best-Infertility-Treatment-Doctor-in-Jabalpur",
  },
  {
    title: `Best Oligospermia Treatment in Jabalpur`,
    author: "Dr. Archana Srivastava",
    date: "28 November, 2024",
    href: "/Best-Oligospermia-Treatment-in-Jabalpur",
  },
  {
    title: `Infertility Treatment Doctor Jabalpur`,
    author: "Dr. Archana Srivastava",
    date: "15 January, 2025",
    href: "/Infertility-Treatment-Doctor-Jabalpur",
  },
];

export default function Sidebar() {
  return (
    <div className="cs_sidebar">

      <div className="cs_sidebar_item">
        <RecentPostWidget title="Popular Articles" data={recentPostData} />
      </div>
    </div>
  );
}
